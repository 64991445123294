<template>
	<div>
		<el-form ref="defForm" :rules="rules" :model="defForm" label-width="80px">
			<el-form-item label="名称" prop="name">
				<el-input v-model="defForm.name"></el-input>
			</el-form-item>
			<el-form-item label="轮播图片" prop="url">
				<el-upload 
					class="avatar-uploader" 
					action="string" 
					:show-file-list="false" 
					:http-request="uploadImg"
					:before-upload="beforeAvatarUpload">
					<img v-if="defForm.url" :src="defForm.url" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>	
			<el-form-item label="是否禁用" prop="status">
				<el-switch v-model="defForm.status" active-color="#13ce66" inactive-color="#999999">
				</el-switch>
			</el-form-item>
			<el-form-item size="large">
				<el-button type="primary" @click="close">取消</el-button>
				<el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {update,upload} from '@/api/slideshow'
	import token from '@/token.js'
	export default {
		name: "Edit",
		props: ['data'],
		data() {
			return {
				defForm: {
					id:0,
					name: '',
					url: '',
					status: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '必填项不能为空'
					}],
					url: [{
						required: true,
						message: '必填项不能为空'
					}],
				}
			}
		},
		created() {
			let editItem=JSON.parse(JSON.stringify(this.data))
			this.defForm.id = editItem.id
			this.defForm.name = editItem.name
			this.defForm.url = editItem.url
			this.defForm.status = editItem.status=='N'?false:true
		},
		methods: {
			submitForm() {
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						update(this.defForm).then(data => {
							this.loading = false
							this.$message.success("轮播图["+this.defForm.name+"]编辑成功!")
							this.$refs['defForm'].resetFields()
							this.$emit("after")
							this.$emit("hideDialog")
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			close() {
				this.$emit("hideDialog")
			},
			uploadImg(param) {
				let formData = new FormData();
				formData.append("file", param.file)
				upload(formData).then(data => {
					this.defForm.url = data.data
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//上传之前的验证
			beforeAvatarUpload(file) {
				const isIMAGE = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png')
				if (!isIMAGE) {
					this.$message.error('上传文件只能是图片格式!')
				}
				return isIMAGE 
			},
		}
	}
</script>

<style scoped>

.avatar-uploader {
		height: 80px;
		width: 80px;
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		img{
			border-radius: 6px;
			height: 80px;
			width: 80px;
		}
	}

	.avatar-uploader:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	.avatar {
		width: 80px;
		height: 80px;
		display: block;
	}
</style>
