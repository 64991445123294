import axiosInstance from "./axiosInstance";

//新增用户
export function create(param) {
	return axiosInstance.post('slideshow/addSlideshow', param)
}
//编辑用户
export function update(param) {
	return axiosInstance.post('slideshow/editSlideshow', param)
}
//删除用户
export function del(param) {
	return axiosInstance.post('slideshow/deleteSlideshow', param)
}
//分页查询用户列表
export function query(param) {
	return axiosInstance.post('slideshow/querySlideshowList', param)
}

export function upload(formData) {
	return axiosInstance.post('file/obsUploadFile', formData)
}
