<template>
	<div>
		<div>
			<el-form :inline="true" ref="queryForm" :model="queryForm">
				<el-form-item>
					<el-input v-model="queryForm.name" placeholder="按名称查询"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="queryForm.status" clearable  placeholder="按状态查询">
						<el-option v-for="(item,index) in statusList" :key="index" :label="item.label" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" @click="list" type="danger">查询</el-button>
					<el-button icon="el-icon-plus" @click="add" type="primary">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData">
			<el-table-column prop="name" label="名称" min-width="200"></el-table-column>
			<el-table-column prop="status" label="状态" min-width="200"></el-table-column>
			<el-table-column prop="url" label="链接" min-width="200" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="url" label="图片" min-width="250" align="center">
				<template slot-scope="scope">            
					<img :src="scope.row.url" width="70" height="70" />
				</template>  
			</el-table-column>
			<el-table-column align="center" label="操作" min-width="250">
				<template slot-scope="scope">
					<el-link @click="edit(scope.row)" :underline="false" type="primary" class="el-icon-edit-outline">编辑</el-link>
					<span style="display: inline-block;width: 20px;"></span>
					<el-link @click="del(scope.row)" :underline="false" type="danger" class="el-icon-delete">删除</el-link>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination 
			background 
			style="margin-top: 20px;"
			layout="total,prev, pager, next,jumper" 
			@current-change="handleCurrentChange"
			:current-page="pageNo" 
			:total="total">
		</el-pagination>

		<el-dialog :visible.sync="addVisible" v-if="addVisible" :close-on-click-modal="false">
			<Add @after="list" @hideDialog="hidden"></Add>
		</el-dialog>

		<el-dialog :visible.sync="editVisible" v-if="editVisible" :close-on-click-modal="false">
			<Edit @after="list" @hideDialog="hidden" :data="formData"></Edit>
		</el-dialog>

	</div>
</template>

<script>
	import {del,query} from "../../../../api/slideshow.js";
	import Add from './SlideshowAdd.vue'
	import Edit from './SlideshowEdit.vue'
	import token from '@/token.js'

	export default {
		name: "Index",
		components: {
			Add,
			Edit
		},
		data() {
			return {
				nowUserID:token.getUser().id,
				queryForm: {
					name: '',
					status: '',
				},
				defForm:{
					id:0,
					name: '',
					url: '',
					status: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
				statusList:[
					{
						id:'N',
						label:'未禁用'
					},
					{
						id:'Y',
						label:'禁用'
					}
				],
				tableData: [],
				formData: {},
				total: 0,
				pageNo: 1,
				addVisible: false,
				editVisible: false,
			}
		},
		mounted() {
			this.list();
		},
		methods: {
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.list()
			},
			//查询用户列表
			list() {
				let param={
					name: this.queryForm.name,
					status: this.queryForm.status,
					page: this.pageNo,
					pageSize: 10,
				}
				query(param).then(data => {
					this.tableData = data.data.list
					this.total = data.data.total
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//新增轮播图信息
			add() {
				this.addVisible = true
			},
			edit(row) {
				this.editVisible = true
				this.formData = row
			},
			del(row) {
				this.defForm.id = row.id
				this.defForm.name = row.name
				this.defForm.url = row.url
				this.defForm.status = row.status=='N'?false:true
				this.defForm.updateUserID=token.getUser().id,
				this.defForm.updateUserName=token.getUser().manager_username,
				
				this.$confirm('确定要删除吗？', "提示",{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					del(this.defForm).then(data => {
						let totalPage = Math.ceil((this.total - 1) / 10); //15 是每页15条
						let pageNo = this.pageNo > totalPage ? totalPage : this.pageNo;
						this.pageNo = pageNo < 1 ? 1 : pageNo; //this.pageNo 当前页数
						this.list()
						this.$message.success("轮播图["+row.name+"]删除成功!")
					}).catch(error => {
						this.$message.error(error)
					})
				}).catch(() => {
					
				})
			},
			hidden() {
				this.addVisible = false
				this.editVisible = false
			},
		}
	}
</script>

<style scoped>
	.container {}
</style>
